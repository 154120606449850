@font-face {
    font-family: 'circular-black';
    src: url('../assets/CircularStd-Black.otf'); 
} 
body{
    font-family: "DM Sans", sans-serif;
}
a{
    text-decoration: none;
}
.title-1{
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 900;
    letter-spacing: -0.02em;
    line-height: 1.2;
    color: var(--black);
    font-family: circular-black, Helvetica, Arial, sans-serif;
}
.title-2{
    margin-top: 30px;
    margin-bottom: 0px;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -0.25px;
    line-height: 1.5;
    color: var(--white);
}
.subtitle-1{
    font-size: 24px;
    letter-spacing: -0.01em;
    font-weight: 900;
    line-height: 1.4;
    margin-top: 24px;
    margin-bottom: 12px;
    color: var(--black);
    font-family: circular-black, Helvetica, Arial, sans-serif;
}
.parag-1{
    margin: .5em 0 1em;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    color: var(--black);
}
.a_link{
    font-size: 13px;
    line-height: 1;
    font-weight: 700;
    color: var(--linkgreen);
    text-decoration: none;
    transition-duration: 0.3s;
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: normal;
}
.a_link:hover, .a_link:focus {
    color: var(--hvrgreen);
    text-decoration: none;
}
/*
    Nav fonts
*/
.navigation-link{
    padding: 0px 17px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
}
.navigation-link.son{
    padding-right: 0px;
}
.navigation-link.faded{
    color: var(--faded);
}
.navigation-link:hover{
    color: var(--hvrgreen);
}
.sidenav .sidenav-link{
    cursor: pointer;
    padding: 0 0 25px;
    display: block;
    line-height: 1;
    font-weight: 700;
    font-size: 34px;
    color: var(--white);
}
.sidenav .sidenav-link.light{
    font-weight: 400;
    font-size: 26px;
}
.sidenav .sidenav-link:hover{
    color: var(--hvrgreen);
}
/*
    Nav fonts
*/

.hm_cont small{
    font-size: 12px;
    color: var(--white);
    font-weight: 700;
}
.hm_cont h1,
.premium-head h1{
    font-weight: 900;
    letter-spacing: -0.04em;
    line-height: 1.15;
    font-size: 80px;
    margin-top: 0;
    margin-bottom: 0;
    color: var(--white);
    font-family: circular-black, Helvetica, Arial, sans-serif;
}
.premium-head h1{
    letter-spacing: 0.25px;
}
.hm_cont h3{
    font-size: 24px;
    letter-spacing: -0.01em;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 900;
    line-height: 1.4;
    color: var(--white);
    font-family: circular-black, Helvetica, Arial, sans-serif;
}
.hm_card .box_details h2{
    font-size: 14px;
    font-weight: 400;
    margin: 16px 0 0;
    letter-spacing: -0.015em;
    line-height: 1.3;
    color: var(--white);
}
.last_listen h1{
    margin-bottom: 80px;
    font-size: 48px;
    font-weight: 900;
    letter-spacing: -0.02em;
    line-height: 1.2;
    color: var(--white);
    font-family: circular-black, Helvetica, Arial, sans-serif;
    text-align: center;
}
.last_listen .ll_card span h5{
    background-color: rgba(30, 50, 100, 0.25);
    padding: 4px 16px;
    display: inline-block;
    margin: 16px 0 16px -7px;
    font-size: 12px;
    line-height: 18px;
    color: var(--white);
    font-weight: 400;
}
.last_listen .ll_card span h2{
    margin: 0px;
    margin-left: -25px;
    width: 125%;
    position: relative;
    z-index: 2;
    background-color: #1E3264;
    padding: 12px 40px;
    font-size: 24px;
    letter-spacing: -0.01em;
    color: var(--white);
    font-family: circular-black, Helvetica, Arial, sans-serif;
}
.last_listen .ll_card span a{
    display: inline-block;
    color: var(--hvrgreen);
    font-size: 13px;
    line-height: 14px;
    font-weight: 700;
    margin-left: 22px;
    margin-top: 20px;
    letter-spacing: 1px;
}
.premium-head p{
    font-size: 12px;
    color: var(--white);
    margin: 0px;
}
.premium-head p small{
    font-size: 12px;
    color: var(--white);
}
.premium-head p small a{
    font-size: 12px;
    line-height: 15px;
    color: var(--white);
    text-decoration: underline;
}
.why-premium h2{
    text-align: center;
}
.why-card h6{
    font-size: 21px;
    padding: 0px;
    line-height: 1.3em;
    font-weight: 700;
    margin: 0 auto 10px auto;
}
.why-card p{
    line-height: 1.5;
    font-size: 16px;
    padding: 0px;
    margin: 0;
    font-weight: 400;
    margin: 0px auto;
}
.plans-text h2{
    font-size: 38px;
    letter-spacing: -1.6px;
    line-height: 1;
    text-align: center;
    margin: 0px;
    padding: 0px;
    font-weight: 900;
    font-family: circular-black, Helvetica, Arial, sans-serif;
}
.plans-text p{
    font-size: 16px;
    font-weight: normal;
    margin: 10px 0px;
    text-align: center;
    padding: 0px;
    line-height: 20px;
    letter-spacing: 0.25px;
}
/*
    Footer
*/
.footer-nav p{
    margin: 20px 0;
    color: var(--secondary);
    font-size: 12px;
    font-weight: 900;
    line-height: 1.4;
    letter-spacing: .08em;
    text-transform: uppercase;
    font-family: circular-black, Helvetica, Arial, sans-serif;
}
.footer-nav .fn-left div,
.footer-nav .fn-mid div,
.footer-nav .fn-right div{
    margin: 0;
}
.footer-nav div a{
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 0 15px;
    color: var(--white);
}
.footer-nav div a:hover{
    color: var(--hvrgreen);
}
footer .footer_bot ul li a{
    font-size: 12px;
    color: var(--secondary);
    padding: 1em 0;
    display: inline-block;
}
footer .footer_bot ul li a:hover{
    color: var(--hvrgreen);
}
footer .footer_bot span{
    font-size: 12px;
    color: var(--secondary);
}
footer .tr a{
    color: var(--secondary);
    font-size: 12px;
}
footer .tr a:hover{
    color: var(--hvrgreen);
}
/*
    Footer
*/