:root {
    --fadednav: rgba(0, 0, 0, 0.6);
	--black: #000;
	--white: #fff;
    --faded: #d9dadc;
    --linkgreen: #1DB954;
    --hvrgreen: #1ed760;
    --homebgcolor:#2D46B9;
    --secondary: #919496;
    --premiumblue: #1d75de;
}
html{
    scroll-behavior: smooth;
}
* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
body {
	margin: 0;
}
.container {
	width: 1170px;
	margin: 0 auto;
	padding: 0 15px;
}
.loading-container {
    position: relative;
    height:100vh;
    align-items: center;
    justify-content:center;
    display:flex
}
.opacity{
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
}
.opacity.opa {
    background-color: var(--fadednav);
    z-index: 8;
    overflow-x: hidden;
}
body.opa{
    overflow-y: hidden;
}
/*
    Header
*/
header {
    background-color: var(--fadednav);
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 9;
    transition: .4s;
}
.header {
	display: grid;
	grid-template-columns: 150px auto;
	align-items: center;
    height: 80px;
}
.navigation ul {
	padding: 0px;
	margin: 0px;
	width: fit-content;
	margin-left: auto;
    display: flex;
    align-items: center;
}
.navigation ul li {
    display: inline-block;
    position: relative;
}
.header .logo {
	width: auto;
	height: 60px;
}
.header .logo svg {
	fill: var(--white);
}
.header ul li a span {
    display: block;
    width: 24px;
    height: 24px;;
}

.vertical_separator {
	display: inline-block;
	height: 16px;
	width: 1px;
	background-color: var(--white);
	margin: auto 17px;
}
.profile_btn{
    margin-left: 7px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    font: inherit;
    font-weight: 700;
    outline: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
}
.usericon{
    border: 2px solid var(--white);
    margin: auto 6px auto 10px;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    display: inline-block;
}
.profile_btn .usericon{
    display: inline-block;
}
.usericon svg{
    padding: 7px;
    fill: var(--white);
    height: 100%;
    width: 100%;
}
.profile-title{
    margin: auto 0;
    display: inline-block;
}
.profile-title span{
    margin: auto 0 auto 10px;
    display: inline-block;
    color: var(--white);
}
.profile-title svg{
    margin: auto 0 auto 6px;
    fill: var(--white);
    width: 12px;
    height: 12px;
}
.profile_btn:hover .profile-title span{
    color: var(--hvrgreen);
}
.profile_btn:hover .usericon{
    border-color: var(--hvrgreen);
}
.profile_btn:hover .usericon svg,
.profile_btn:hover .profile-title svg{
    fill: var(--hvrgreen);
}
.profileMenu{
    display: none;
    position: absolute;
    top: 100%;
    margin-top: 8px;
    right: -11px;
}
.profileMenu.active{
    display: block !important;
}
.profile-title svg.transform{
    transform: rotateZ(180deg);
}
.profileMenu ul{
    margin: 2px 0 0;
    background-color: var(--white);
    border-radius: 3px;
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: baseline;
    min-width: 160px;
}
.profileMenu ul:before{
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    right: 7px;
    border-bottom: 16px solid var(--white);
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    top: -12px;
}
.profileMenu ul li{
    padding: 0;
    display: block;
    letter-spacing: 0;
}
.profileMenu ul li a{
    padding: 4px 16px 3px;
    display: block;
    line-height: 1.5;
    font-size: 16px;
    font-weight: 700;
    color: var(--black);
    text-align: left;
}
.profileMenu ul li a.faded{
    color: var(--secondary);
}
.profileMenu ul li a:hover{
    color: var(--hvrgreen);
}
.profile_icon-mobile{
    margin-left: auto;
    display: none;
    align-items: center;
}
.container-togg{
	display: none;
    cursor: pointer;
    margin-left: auto;
    position: relative;
    z-index: 99;
}
.usericon {
	display: none;
    cursor: pointer;
    margin-left: auto;
    position: relative;
}
.bar1,
.bar2,
.bar3 {
	width: 22px;
	height: 3px;
	background-color:var(--white);
	margin: 4px 0;
    transition: 0.2s;
    border-radius: 1px;
}
.change .bar1 {
	-webkit-transform: rotate(-45deg) translate(-4px, 2px);
	transform: rotate(-45deg) translate(-4px, 2px);
}
.change .bar2 {
	opacity: 0;
}
.change .bar3 {
	-webkit-transform: rotate(45deg) translate(-7px, -7px);
	transform: rotate(45deg) translate(-7px, -7px);
}
.sidenav {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 9;
	top: 0;
	right: 0;
	background-color: var(--black);
	overflow-x: hidden;
    transition: 0.25s;
    transition-timing-function: ease-in;
    opacity: 0;
    border-width: 0;
    margin: 0;
    padding: 38px 0px 38px 0px;
    max-width: 100vw !important;
}
.sidenav ul{
    margin: 0;
    padding: 0;
    display: inline;
    list-style: none;
}
.sidenav ul li{
    margin: 0;
    display: block;
}
.sidenav .h_separator{
    margin: 18px 0 40px;
    height: 2px;
    width: 20px;
    background-color: var(--white);
}
.sidenav-logo{
    display: inline-block;
    height: 27px;
    width: 89px;
    position: absolute;
    bottom: 38px;
    left: 38px;
}
.sidenav-logo svg{
    fill: var(--white);
}
.sidenav.open {
    width: 447px !important;
    opacity: 1 !important;
    padding: 38px !important;
}
/*
    Header
*/

.home_main{
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: -80px;
}
.hm_gradient-1{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #509BF5;
    background-image: -webkit-gradient(linear, left top, right top, from(#c074b2), to(#8ab5e8));
    background-image: linear-gradient(90deg, #c074b2, #8ab5e8);
}
.hm_gradient-2{
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000));
    background-image: linear-gradient(transparent, #000);
}
.hm_cont{
    display: grid;
    grid-template-columns: 4fr 8fr;
    margin-top: 235px;
    position: relative;
}
.home_text{
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: baseline;
    padding-left: 15px;
    padding-right: 15px;
}
.green_btn{
    display: inline-block;
    margin: 32px 0;
    font-size: 16px;
    line-height: 1;
    border-radius: 50px;
    padding: 19px 56px 21px;
    color: var(--white);
    background-color: #1DB954;
    transition-duration: .3s;
    border-width: 0;
    letter-spacing: 2px;
    min-width: 160px;
    text-transform: uppercase;
    white-space: normal;
    border-radius: 50px;
    font-weight: 700;
    cursor: pointer;
}
.green_btn:hover{
    background-color: var(--hvrgreen);
}
.black_btn{
    display: inline-block;
    margin: 40px 0;
    font-size: 14px;
    line-height: 1;
    border-radius: 50px;
    padding: 17px 48px;
    color: var(--white);
    background-color: rgb(25, 20, 20);
    border: 2px solid rgb(25, 20, 20) !important;
    transition-duration: .3s;
    border-width: 0;
    letter-spacing: 2px;
    min-width: 160px;
    text-transform: uppercase;
    white-space: normal;
    border-radius: 50px;
    font-weight: 700;
    cursor: pointer;
}
.black_btn:hover{
    transform: scale(1.04);
    background-color: rgb(83, 79, 79);
}
.home_cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 233px 233px;
    column-gap: 16px;
    row-gap: 16px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0px;
}
.hm_card{
    position: relative;
    cursor: pointer;
}
.box img {
    width: 100%;
    height: 233px;
    object-fit: cover;
}
.box_details{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    text-align: center;
    padding: 10px;
}
.play-icon{
    width: 25px;
    height: 25px;
}
.hm_card:hover .box_details{
    opacity: 1;
}
.arrow-scroll-down{
    width: 32px;
    height: 43px;
    display: block;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 120px;
}
.arrow-scroll-down svg{
    fill: #fff;
    width: 32px;
    height: 43px;
    position: relative;
}
.last_listen{
    background-color: #1E3264;
    background-image: -webkit-gradient(linear, left top, right top, from(#2c5e92), color-stop(80%, #552f6d));
    background-image: linear-gradient(90deg, #2c5e92 0%, #552f6d 80%);
}
.last_listen .container{
    padding-top: 60px;
    padding-bottom: 160px;
}
.last_listen .ll_row{
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 30px;
    gap: 50px;
}
.last_listen .ll_card{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 250px;
    position: relative;
}
.last_listen .ll_card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.last_listen .ll_card .txt{
    position: relative;
    z-index: 3;
}
.last_listen .ll_card .img{
    position: relative;
    z-index: 2;
    position: relative;
    left: 15px;
    bottom: 25px;
}
.last_listen .bg_filt{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #ffffff;
    opacity: 0.05;
}
.home_links{
    padding-top: 60px;
    padding-bottom: 80px;
}
.home_links .container.links{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
}
.home_links .web_link{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.home_links .web_link a{
    margin-top: auto;
}
.home_links .web_link img{
    width: 180px;
    height: 180px;
    margin: 10px auto 20px;
}
.premium-head{
    background-color: var(--premiumblue);
    padding-top: 80px;
}
.premium-head .container{
    padding: 141px 15px;
}
.why-premium{
    padding: 75px 0px 110px 0px;
}
.why-cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 15px;
    margin-top: 72px;
}
.premium-plans{
    padding: 75px 0px;

    background: rgb(248, 248, 248);
}
.plans-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 15px;
    margin-top: 75px;
    margin-bottom: 132px;
}
/*Download*/
.download{
    background-color: #19e68c;
    padding: 40px 15px 104px;
}
.download .container{
    text-align: center;
}
.download img{
    width: 136px;
    margin-bottom: 40px;
    margin-top: 64px;
}
.download h1{
    margin: 0;
    padding-bottom: 24px;
}
.download p{
    padding: 0px 0px 1em;
    margin-top: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
}
.download .black_btn{
    background-color: white;
    color: #000;
    border: 0 !important;
    margin: 0;
    min-width: auto;
}
.phoneNtablet{
    background-color: white;
    padding: 70px 15px;
}
.phoneNtablet .container{
    text-align: center;
}
.phoneNtablet h3{
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.5px;
    margin: 0;
    padding: 0px 0px 0.75em;
}
.phoneNtablet p{
    padding: 0px 0px 1em;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.phoneNtablet .storebox a{
    display: inline-block;
    margin: 10px;
}
.Everywhere{
    background-color: rgb(34, 35, 38);
    padding: 70px 15px 50px;
}
.Everywhere .container{
    text-align: center;
}
.Everywhere img{
    width: 100%;
    max-width: 560px;
    margin-bottom: 20px;
    padding: 0px 40px;
}
.Everywhere h3{
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.5px;
    color: white;
    padding: 0px 0px 0.75em;
    margin-top: 0px;
    margin-bottom: 0px;
}
.Everywhere .platform_name,
.Everywhere .platform_link{
    display: inline-block;
}
.Everywhere .platform_name li,
.Everywhere .platform_link li{
    display: inline-block;
    color: white;
    margin-top: -6px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    padding-bottom: 1em;
}
.Everywhere .platform_name li:not(:last-child)::after,
.Everywhere .platform_link li:not(:last-child)::after {
    font-size: 22px;
    content: "•";
    padding-left: 14px;
    padding-right: 14px;
    color: rgb(29, 185, 84);
}
.Everywhere .platform_link li a{
    color: rgb(29, 185, 84);
    text-decoration: underline;
}
/*Download*/
/*
    Footer
*/
footer{
    padding: 80px 0 50px;
    background-color: var(--black);
}
footer .logo { 
    grid-area: logo;
}
footer .logo{ 
    width: 100%;
    max-width: 131px;
}
footer .footer-nav { 
    /* display: grid; */
    color: white;
}
.footer-nav h4 {
    margin-top: 30px;
    margin-bottom: 10px;
}
.footer-nav .fn-left,
.footer-nav .fn-mid,
.footer-nav .fn-right{
    padding: 0 15px;
    margin: 0;
}
footer .social { grid-area: social; }
footer .social ul{
    margin: 20px 0 0;
    padding: 0;
    width: fit-content;
    margin-left: auto;
}
footer .social ul li{
    display: inline-block;
}
footer .social ul li:not(:last-child) { 
    margin-right: 10px;
}
footer .social ul li a{
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: #222326;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
}
footer .social ul li a span {
    display: block;
    width: 24px;
    height: 24px;
}
.insta{
    background: no-repeat url(../assets/insta.svg);
}
 .youtube{
    background: no-repeat url(../assets/youtube.svg);
}
.whatsapp{
    background: no-repeat url(../assets/whatsapp.svg);
}
footer .social ul li a:hover {
    background-color: var(--hvrgreen);
}
footer .tr { 
    grid-area: tr;
    margin: 0;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
footer .tr a{
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    font-size: 10px;
    text-decoration: none;
}
footer .tr img{
    height: 18px;
    margin-left: 10px;
}
footer .footer_bot { 
    grid-area: footer_bot; 
    display: flex;
    width: 100%;
}
footer .footer_bot ul{
    margin: 0 45px 0 0;
    padding: 0;
}
footer .footer_bot span{
    margin: 0 0 0 auto;
    padding: 1em 0;
}
footer .footer_bot ul li{
    list-style: none;
    display: inline-block;
    line-height: normal;
    letter-spacing: 0;
    padding: 0;
    margin-right: 24px;
}
.footer-cont {
    display: grid;
    width: 100%;
    grid-template-columns: 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33%;
    grid-template-rows: 200px auto auto;
    grid-template-areas:
        'logo logo footer-nav footer-nav footer-nav footer-nav footer-nav footer-nav social social social social'
        'tr tr tr tr tr tr tr tr tr tr tr tr'
        'footer_bot footer_bot footer_bot footer_bot footer_bot footer_bot footer_bot footer_bot footer_bot footer_bot footer_bot footer_bot';
}
/*
    Footer
*/